<template>
  <div class="wrap">
    <div class="header">
      <div class="header_left">
        <img :src="imgUrl" alt="" />
      </div>
      <div class="header_right">
        <h3>东兴口岸北仑河二桥验货场</h3>
        <div class="info">
          <div class="info_text">{{ $t("home.dx_text_1") }}</div>
          <div class="info_text">{{ $t("home.dx_text_2") }}</div>
        </div>
      </div>
    </div>
    <div class="content">
      <div>
        <div class="item_title">
          <span></span>
          关于口岸
          <span></span>
        </div>
        <div class="about_content">
          <el-row
            :gutter="20"
            type="flex"
            style="flex-wrap: wrap"
            justify="space-between"
          >
            <el-col v-for="(item, index) in aboutList" :key="index" :span="7">
              <div class="col_content">
                <img :src="item.imgUrl" alt="" class="img" />
                <div class="text_wrap">
                  <span>{{ item.text }}</span>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div>
        <div class="item_title">
          <span></span>
          服务介绍
          <span></span>
        </div>
        <div class="about_content">
          <el-row
            :gutter="20"
            type="flex"
            style="flex-wrap: wrap"
            justify="space-between"
          >
            <el-col v-for="(item, index) in aboutList" :key="index" :span="7">
              <div class="col_content">
                <img :src="item.imgUrl" alt="" class="img" />
                <div class="text_wrap">
                  <span>{{ item.text }}</span>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 业务模式 -->
      <div>
        <div class="item_title">
          <span></span>
          业务模式
          <span></span>
        </div>
        <div class="about_content">
          <el-row
            :gutter="20"
            type="flex"
            style="flex-wrap: wrap"
            justify="space-between"
          >
            <el-col
              v-for="(item, index) in bussinessList"
              :key="index"
              :span="5"
            >
              <div class="col_content">
                <img :src="item.imgUrl" alt="" class="img" />
                <div class="text_wrap">
                  <span>{{ item.text }}</span>
                  <div class="message_box">
                    <div
                      class="message"
                      v-for="(message, idx) in item.messageList"
                      :key="idx"
                    >
                      {{ message }}
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 服务优势 -->

      <div>
        <div class="item_title">
          <span></span>
          服务优势
          <span></span>
        </div>
        <div class="about_content">
          <el-row
            :gutter="20"
            type="flex"
            style="flex-wrap: wrap"
            justify="space-between"
          >
            <el-col
              v-for="(item, index) in bussinessList"
              :key="index"
              :span="5"
            >
              <div class="col_content">
                <img :src="item.imgUrl" alt="" class="img" />
                <div class="text_wrap">
                  <span>{{ item.text }}</span>
                  <div class="message_box">
                    <div
                      class="message"
                      v-for="(message, idx) in item.messageList"
                      :key="idx"
                    >
                      {{ message }}
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgUrl: require("@/assets/cases/dx.png"),
      bussinessList: [
        {
          imgUrl: require("@/assets/cases/bg.webp"),
          text: "进口重车",
          messageList: [
            "1.越卡进口",
            "2.场内等待缴费后刷卡出场",
            "3.如需查验则待查验解控后刷卡出场",
            "4.放行由中卡出",
          ],
        },
        {
          imgUrl: require("@/assets/cases/bg.webp"),
          text: "进境粮食指定监管场地",
          messageList: [
            "1.越卡进口",
            "2.场内等待缴费后刷卡出场",
            "3.如需查验则待查验解控后刷卡出场",
            "4.放行由中卡出",
          ],
        },
        {
          imgUrl: require("@/assets/cases/bg.webp"),
          text: "进境中草药指定监管场地",
          messageList: [
            "1.越卡进口",
            "2.场内等待缴费后刷卡出场",
            "3.如需查验则待查验解控后刷卡出场",
            "4.放行由中卡出",
          ],
        },
        {
          imgUrl: require("@/assets/cases/bg.webp"),
          text: "进境冰鲜水产品指定监管场地",
          messageList: [
            "1.越卡进口",
            "2.场内等待缴费后刷卡出场",
            "3.如需查验则待查验解控后刷卡出场",
            "4.放行由中卡出",
          ],
        },
      ],
      aboutList: [
        {
          imgUrl: require("@/assets/cases/bg.webp"),
          text: "进境水果指定监管场地",
        },
        {
          imgUrl: require("@/assets/cases/bg.webp"),
          text: "进境粮食指定监管场地",
        },
        {
          imgUrl: require("@/assets/cases/bg.webp"),
          text: "进境中草药指定监管场地",
        },
        {
          imgUrl: require("@/assets/cases/bg.webp"),
          text: "进境冰鲜水产品指定监管场地",
        },
        {
          imgUrl: require("@/assets/cases/bg.webp"),
          text: "进境食用水生动物指定监管场地",
        },
        {
          imgUrl: require("@/assets/cases/bg.webp"),
          text: "进境中草药指定监管场地",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  width: calc(100% - 800px);
  min-width: 1000px;
  margin: 30px auto;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    .header_left {
      width: 55%;
      height: auto;
      max-height: 400px;
      margin-right: 5%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .header_right {
      width: 40%;
      h3 {
        text-align: right;
        margin-bottom: 20px;
      }
      .info_text {
        font-size: 14px;
        line-height: 24px;
        color: #666;
        margin-bottom: 12px;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    .item_title {
      text-align: center;
      padding: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        display: inline-block;
        width: 130px;
        height: 2px;
        background: #333;
        margin: 0 16px;
      }
      > div {
        font-size: 30px;
        color: #515151;
        font-weight: 600;
      }
      .about_content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }
}
.col_content {
  width: 100%;
  height: 260px;
  position: relative;
  margin-bottom: 10px;
  .img {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .text_wrap {
    // z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    -webkit-transition: all 0.4s;
    transition: all 1s;
    span {
      color: #fff;
      margin-bottom: 12px;
    }
    .message_box {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .message {
        color: #fff;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}
</style>